<template>
  <div class="rank-comparative-table">
    <SimpleInfoTable
      :columns="{
        'country': {
          header: 'Country',
          orientation: 'left',
          sortBy: 'country',
          sortExportParam: 'country'
        },
        'first_impression': {
          header: 'Est. Impression',
          subHeader: this.formatDate(this.firstDate, 'month-day-year'),
          sortBy: 'first_impression'
        },
        'last_impression': {
          header: 'Est. Impression',
          subHeader: this.formatDate(this.lastDate, 'month-day-year'),
          sortBy: 'last_impression'
        },
        'total_changes': {header: 'Total Changes', sortBy: 'total_changes'},
      }"
      :default-sort-index="'total_changes'"
      :default-sort-direction="'desc'"
      :data-provider="dataProvider"
      :show-totals="showTotals"
      :full-width-section="true"
      :classes="['main-table']"
      @refresh-data="refreshData"
      :trClickCallback="openCountry"
      :tr-class-callback="trClassCallback"
    >
      <template v-slot:items-country="slotProps">
        <div class="display-flex f-align-center f-j-between full-width">
          <div>
            <div class="country-title">
              <dynamic-image classes="country-flag"
                             :width="14"
                             :height="10"
                             v-if="slotProps.item.country_code"
                             :country-code="slotProps.item.country_code"/>
              <div>
                {{ slotProps.item.country }}
              </div>
            </div>
          </div>

          <svg-icon icon="angle-down-solid"
                    class="cursor-pointer open-full-section"
                    :class="{rotate180: slotProps.item.opened}"/>
        </div>
      </template>
      <template v-slot:items-first_impression="slotProps">
        <div class="impression-value">
          <div class="progress-wrap">
            <progress-bar :width="calculateImpressionPercents(slotProps.item.first_impression) + '%'"
                          height="8px"
                          :fill-color="`background: ${getImpressionBarColor(slotProps.item.first_impression)}`"
                          bar-type="custom"/>
          </div>
          <div class="value-block" :style="`width: ${impressionValueBlockWidth}px`">
            {{ formatNumber(slotProps.item.first_impression) }}
          </div>
        </div>
      </template>
      <template v-slot:items-last_impression="slotProps">
        <div class="impression-value">
          <div class="progress-wrap">
            <progress-bar :width="calculateImpressionPercents(slotProps.item.last_impression) + '%'"
                          height="8px"
                          :fill-color="`background: ${getImpressionBarColor(slotProps.item.last_impression)}`"
                          bar-type="custom"/>
          </div>
          <div class="value-block" :style="`width: ${impressionValueBlockWidth}px`">
            {{ formatNumber(slotProps.item.last_impression) }}
          </div>
        </div>
      </template>
      <template v-slot:items-total_changes="slotProps">
        <value-change :change="slotProps.item.total_changes" />
      </template>

      <template v-slot:total-country>
        <span>Totals:</span>
      </template>
      <template v-slot:total-first_impression>
        {{ formatNumber(totals.first_impression) }}
      </template>
      <template v-slot:total-last_impression>
        {{ formatNumber(totals.last_impression) }}
      </template>
      <template v-slot:total-total_changes>
        <value-change :change="totals.total_changes" />
      </template>

      <template v-slot:full-width-sections="slotProps">
        <div class="opened"
             v-if="slotProps.item.opened">
          <SimpleInfoTable
            :columns="{
              'keyword': {
                header: 'Keyword',
                orientation: 'left',
                sortBy: 'keyword',
                headerStyle: {width: '100%', minWidth: '170px'}
              },
              'popularity': {header: 'Popularity', sortBy: 'sap'},
              'popularity_change': {header: 'Popularity Change', sortBy: 'popularity_change'},
              'first_rank': {
                header: 'App Rank',
                subHeader: this.firstDateFormatted,
                sortBy: (item) => this.getRankForSort(item.first_rank),
                headerStyle: {minWidth: '130px'}
              },
              'last_rank': {
                header: 'App Rank',
                subHeader: this.lastDateFormatted,
                sortBy: (item) => this.getRankForSort(item.last_rank),
                headerStyle: {minWidth: '130px'}
              },
              'rank_difference': {
                header: 'App Rank Change',
                sortBy: (item) => this.getRankDifferenceForSort(item.rank_difference),
                headerStyle: {minWidth: '130px'}
              },
              'first_impression': {
                header: 'Est. Impr.',
                subHeader: this.firstDateFormatted,
                sortBy: 'first_impression',
                headerStyle: {minWidth: '130px'}
              },
              'last_impression': {
                header: 'Est. Impr.',
                subHeader: this.lastDateFormatted,
                sortBy: 'last_impression',
                headerStyle: {minWidth: '130px'}
              },
              'impression_change': {
                header: 'Est. Impr. Change',
                sortBy: 'impression_change',
                headerStyle: {minWidth: '130px'}
              }
            }"
            :default-sort-index="'impression_change'"
            :default-sort-direction="'desc'"
            :items="slotProps.item.list"
            :show-pagination="slotProps.item.list.length > 50"
            :search-by="['keyword']"
            @refresh-data="(searchFilterData, provider) => refreshDataSubTable(slotProps.item, searchFilterData, provider)"
          >
            <template v-slot:searchFilterBlock="subTableProps">
              <div class="mb-10">
                <translate-tool :active="slotProps.item.showTranslate"
                                @translate-clicked="translateClickHandlerInner(slotProps.item, subTableProps.provider)"
                />
              </div>
            </template>

            <template v-slot:items-keyword="slotProps">
              <div>
                <div>
                  <div>
                    {{ slotProps.item.keyword }}
                  </div>
                </div>
                <div class="translated-block">
                  {{ slotProps.item.translation }}
                </div>
              </div>
            </template>
            <template v-slot:items-popularity="subTableProps">
              <div class="sap-value">
                <sap-tool :keyword="subTableProps.item.keyword"
                          :sap="subTableProps.item.sap"
                          :country-code="slotProps.item.country_code"
                          :country-name="slotProps.item.country"
                          :store-key="storeKey"
                />
              </div>
            </template>
            <template v-slot:items-popularity_change="slotProps">
              <value-change :change="slotProps.item.popularity_change" />
            </template>
            <template v-slot:items-first_rank="slotProps">
              <div>
                {{ slotProps.item.first_rank }}
              </div>
            </template>
            <template v-slot:items-last_rank="slotProps">
              <div>
                {{ slotProps.item.last_rank }}
              </div>
            </template>
            <template v-slot:items-rank_difference="slotProps">
              <value-change :change="slotProps.item.rank_difference" />
            </template>
            <template v-slot:items-first_impression="slotProps">
              <div>
                {{ slotProps.item.first_rank === 'N/A' ? 'N/A' : formatNumber(slotProps.item.first_impression) }}
              </div>
            </template>
            <template v-slot:items-last_impression="slotProps">
              <div>
                {{ slotProps.item.last_rank === 'N/A' ? 'N/A' : formatNumber(slotProps.item.last_impression) }}
              </div>
            </template>
            <template v-slot:items-impression_change="slotProps">
              <value-change v-if="slotProps.item.first_rank !== 'N/A' && slotProps.item.last_rank !== 'N/A'"
                            :change="slotProps.item.impression_change" />
            </template>
          </SimpleInfoTable>
        </div>
      </template>
    </SimpleInfoTable>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import DynamicImage from "@/components/UI/DynamicImage/index.vue";
import {ApiDataProvider} from "@/api/modules/tables/api-data-provider";
import {formatDate, formatNumber, colorForProgressBar} from "@/utils/utils";
import ValueChange from "@/views/Reports/components/ValueChange/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";

export default {
  name: 'RankComparativeTable',
  components: {
    ProgressBar,
    AppRankTool,
    ValueChange,
    DynamicImage,
    TranslateTool,
    SapTool,
    SimpleInfoTable,
    SvgIcon
  },
  props: {
    dataProvider: ApiDataProvider,
    firstDate: Date,
    lastDate: Date,
    maxImpression: Number,
    storeKey: String,
  },
  data() {
    return {
    }
  },
  methods: {
    formatNumber,
    formatDate,
    openCountry(item) {
      item.opened = !item.opened;
      if (item.opened && item.showTranslate === true) {
        item.provider.searchFilterData.pagination.reset();
        this.translate(item);
      }
    },
    trClassCallback() {
      return {'cursor-pointer': true};
    },
    getRankForSort(rank) {
      if (!isNaN(rank)) {
        return -rank;
      } else if (rank === '-') {
        return -1000;
      } else {
        return -999;
      }
    },
    getRankDifferenceForSort(difference) {
      if (!isNaN(difference)) {
        return +difference;
      } else if (difference === 'in') {
        return 999;
      } else {
        return -999;
      }
    },
    clearTranslations(list) {
      list.forEach((item) => {
        item.translation = null;
      });

      return list;
    },
    async translateClickHandlerInner(item, provider) {
      item.showTranslate = !item.showTranslate;
      if (item.showTranslate) {
        item.provider = provider;
      }
      await this.translate(item);
    },
    async translate(item) {
      if (!item.showTranslate) {
        this.clearTranslations(item.list);
      } else {
        let keywords = item.provider.getItems().map((item) => {
          return item.keyword;
        });

        let translatesByKeyword = await translates.translateKeywords(this, this.storeKey, keywords);

        item.list.forEach((item) => {
          item.translation = translatesByKeyword[item.keyword] ?? null;
        });
      }
    },
    refreshData(searchFilterData) {
      this.$emit('refresh-data', searchFilterData);
    },
    refreshDataSubTable(item, searchFilterData, provider) {
      item.provider = provider;
      this.translate(item);
    },
    getImpressionBarColor(impression) {
      return colorForProgressBar(this.calculateImpressionPercents(impression));
    },
    calculateImpressionPercents(impression) {
      return Math.round(impression / (this.maxImpression / 100));
    },
  },
  computed: {
    firstDateFormatted() {
      return this.formatDate(this.firstDate, 'month-day');
    },
    lastDateFormatted() {
      return this.formatDate(this.lastDate, 'month-day');
    },
    totals() {
      return this.dataProvider.getTotals();
    },
    showTotals() {
      return this.dataProvider.getTotal() > 1 ? 'bottom' : null;
    },
    impressionValueBlockWidth() {
      if (this.maxImpression < 10) {
        return 10;
      } else if (this.maxImpression < 100) {
        return 19;
      } else if (this.maxImpression < 1000) {
        return 28;
      } else if (this.maxImpression < 10000) {
        return 41;
      } else if (this.maxImpression < 100000) {
        return 50;
      } else if (this.maxImpression < 1000000) {
        return 60;
      } else {
        return 73;
      }
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
