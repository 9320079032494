export const ApiDataProvider = class {
    constructor(searchFilterData) {
        this.searchFilterData = searchFilterData;
        this.items = [];
        this.loading = true;
        this.totals = [];
    }

    getSearchFilterData() {
        return this.searchFilterData;
    }

    setSearchFilterData(data) {
        this.searchFilterData = data;
        this.items = [];
        this.loading = true;
    }

    getSorting() {
        return this.searchFilterData.sorting;
    }

    getPagination() {
        return this.searchFilterData.pagination;
    }

    resetPagination() {
        this.searchFilterData.pagination.reset();
    }

    setItems(items, total, totals) {
        this.items = items;
        if (total !== undefined) {
            this.searchFilterData.pagination.setTotal(total);
        }
        if (totals !== undefined) {
            this.totals = totals;
        }
        this.loading = false;
    }

    getTotal() {
        return this.searchFilterData.pagination.getTotal();
    }

    getItems() {
        return this.items;
    }

    getTotals() {
        return this.totals;
    }

    isLoading() {
        return this.loading;
    }

    generateRequest(likeParam = 'like') {
        return this.searchFilterData.generateFilterString(likeParam)
            + this.getSorting().generateFilterString()
            + this.getPagination().generateFilterString();
    }

    generateCSVRequest(likeParam = 'like') {
        return this.searchFilterData.generateFilterString(likeParam)
            + this.getSorting().generateFilterString()
            + '&per_page=60000';
    }
}
