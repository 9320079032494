<template>
  <div class="calendar-block"
       v-click-outside="outside"
       :class="{active: showDatepicker}">
    <div class="calendar-input"
         @click="showDatepicker = !showDatepicker">
      <div class="input-value">
        {{ formatDate(dateFilter) }}
      </div>
      <div class="input-caret">
        <svg-icon icon="angle-down-solid"/>
      </div>
    </div>

    <div class="calendar-block-wrap" :class="{'right':toRight}">
      <v-date-picker v-model="tempDate"
                     :min-date="minDate"
                     :max-date="maxDate"
                     :locale="calendarLocale"
                     :key="datePickerKey"
                     :columns="columns"/>

      <div class="date-picker-range">
        <template v-if="presets.length > 0">
          <div class="title">
            {{ presetsTitle }}
          </div>
          <div class="range-items-list">
            <div class="range-item"
                 v-for="preset in presets"
                 @click="setPresetDate(preset.date)"
                 :class="{
                 active: formatDate(tempDate, 'digits-dash') === formatDate(preset.date, 'digits-dash')
               }">
              {{ preset.title }}
            </div>
          </div>
        </template>

        <div class="bottom-part">
          <base-button height="30px"
                       width="100%"
                       font-size="14px"
                       border-radius="4px"
                       @click="applyDatePicker">
            Apply
          </base-button>
          <base-button mode="outline"
                       height="30px"
                       width="100%"
                       font-size="14px"
                       border-radius="4px"
                       style="margin-top: 10px"
                       @click="closeDatePicker">
            Cancel
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DatePicker} from 'v-calendar';
import {formatDate} from "@/utils/utils";

export default {
  name: "DatepickerSingle",
  props: {
    toRight: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean
    },
    minDate: {
      type: Date,
    },
    maxDate: {
      type: Date,
    },
    dateFilter: {
      type: Date,
    },
    columns: {
      type: Number,
    },
    presets: {
      type: Array,
    },
    presetsTitle: {
      type: String,
    },
  },
  components: {
    'v-date-picker': DatePicker,
  },
  emits: ['date-filter-changed'],
  data() {
    return {
      showDatepicker: false,
      tempDate: new Date(),
      calendarLocale: {
        masks: {weekdays: 'WW'},
      },
      datePickerKey: 0,
    }
  },
  mounted() {
    this.setTempDate(this.dateFilter);
  },
  methods: {
    setPresetDate(date) {
      this.setTempDate(date);
      this.showDatepicker = true;
    },
    applyDatePicker() {
      this.showDatepicker = false;
      this.$emit('date-filter-changed', this.tempDate);
    },
    closeDatePicker() {
      this.showDatepicker = false;
      this.setTempDate(this.dateFilter);
    },
    formatDate(val, format = 'month-day-year') {
      return formatDate(val, format);
    },
    outside() {
      this.closeDatePicker();
    },
    setTempDate(date) {
      this.tempDate = this.fixDate(new Date(date));
      this.datePickerKey++;
    },
    fixDate(date) {
      if (date > this.maxDate) {
        return new Date(this.maxDate);
      } else if (date < this.minDate) {
        return new Date(this.minDate);
      } else {
        return date;
      }
    }
  },
  watch: {
    dateFilter() {
      this.setTempDate(this.dateFilter);
    },
  }
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>
