<template>
  <template v-if="!allowedSize">
    <div class="mobile-notification-wrap">
      <div class="mobile-notification">
        <div class="notification-image">
          <img src="@/assets/images/mobile-notification.svg"
               width="181"
               height="133"
               alt="Mobile notification image"/>
        </div>
        <div class="notification-text">
          Unfortunately, this tool is only available for the desktop and tablet versions 😢
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="rank-comparative-report">
      <div class="free-user-screen new-container" v-if="isFreeUser">
        <div class="header">
          <h1 class="page-title mobile-hidden">
            {{ $route.meta.pageTitle }}
          </h1>
        </div>
        <div class="content">
          <img src="@/assets/images/empty_page.svg">
          <div class="title">
            To access to Reports, please upgrade
          </div>
          <div class="description">
            Upgrade your plan now to get exclusive access to our app's powerful reporting feature
          </div>
          <base-button height="45px"
                       width="160px"
                       font-size="16px"
                       border-radius="4px"
                       :mode="'btn-standard'"
                       @click="openPlansModal">
            Upgrade Now
          </base-button>
        </div>
      </div>

      <div class="new-container" v-else>
        <div class="header">
          <h1 class="page-title mobile-hidden">
            {{ $route.meta.pageTitle }}
          </h1>
          <a :href="downloadUrl"
             class="download-button"
             download>
            <svg-icon icon="download"/>
            Download
          </a>
        </div>
        <div class="search-input-wrap">
          <live-search-with-presets :presets="myApps"
                                    v-if="selectedApp.id !== undefined"
                                    :current-country="countryForLiveSearch"
                                    :selected-app="selectedApp"
                                    store="APP_STORE"
                                    @changed="searchResultsAppClicked"
          />

          <country-selector ref="countrySelector"
                            v-if="countries.length > 0"
                            :all-countries="countries"
                            :selected-outer="selectedCountries"
                            @changed="changeCountries"/>
          <default-spinner v-else/>
        </div>

        <div class="app-date-block">
          <div class="selected-app" v-if="selectedApp.info">
            <span class="logo">
              <app-image
                :width="36"
                :height="36"
                :src="selectedApp.info?.logo"
              />
            </span>
            <div class="selected-title">
              <a :href="selectedApp.info?.store_link" target="_blank">
                <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
              </a>
              <span>{{ selectedApp.info?.title }}</span>
            </div>
            <img v-if="selectedApp.store?.key === 'APP_STORE'"
                 src="@/assets/images/icons/app_store.png"
                 alt="Store icon"
                 class="store-icon"
                 width="22"
                 height="22">
            <img v-else
                 src="@/assets/images/icons/google_play.png"
                 alt="Store icon"
                 class="store-icon"
                 width="22"
                 height="22">
          </div>
          <div class="dates-block">
            <div class="releases-dropdown">
              <div class="filter-title">Release:</div>
              <custom-dropdown :min-width="'140px'"
                               v-if="isReleasesLoaded"
                               :close-dropdown="closeConfigDropdown">
                <template v-slot:header>
                <span v-if="selectedRelease">
                  {{ selectedRelease }}
                  from
                  {{ formatDate(selectedReleaseDate) }}
                </span>
                  <span v-else>Select Release</span>
                </template>
                <template v-slot:content>
                  <div class="select-options">
                    <div class="select-title">
                      Show Report for Release
                    </div>
                    <div v-for="release in releases"
                         class="option"
                         :class="{
                          active: selectedRelease === release.version,
                          disabled: isReleaseDisabled(release.date),
                       }"
                         @click="changeRelease(release.version, release.date)">
                      <div>{{ release.version }} from {{ formatDate(release.date) }}</div>
                    </div>
                  </div>
                </template>
              </custom-dropdown>
              <default-spinner v-else/>
            </div>

            <div class="date-pickers">
              <div class="date-picker">
                <div class="filter-title">Before:</div>
                <datepicker-single
                  :to-right="true"
                  :date-filter="firstDate"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :columns="1"
                  :presets="firstDatePresets"
                  :presets-title="selectedReleaseDate ? 'Date before release:' : 'Date before today:'"
                  @date-filter-changed="firstDate = $event"/>
              </div>
              <div class="date-picker">
                <div class="filter-title">After:</div>
                <datepicker-single
                  :to-right="true"
                  :date-filter="lastDate"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :columns="1"
                  :presets="lastDatePresets"
                  :presets-title="'Date after release:'"
                  @date-filter-changed="lastDate = $event"/>
              </div>
            </div>
          </div>
        </div>

        <div class="filters-block">
          <div class="collapse-button" @click="collapseClickHandler">
            <template v-if="isAllUncollapsed">
              <div>Collapse all</div>
              <svg-icon icon="collapse"/>
            </template>
            <template v-else>
              <div>Uncollapse all</div>
              <svg-icon icon="uncollapse"/>
            </template>
          </div>

          <filters-new :filters="filterConfigs"
                       ref="filters"
                       @apply-filters="filtersApplied"/>

          <custom-dropdown :min-width="'182px'" classes="source-dropdown" :close-dropdown="closeConfigDropdown">
            <template v-slot:header>
              <span>{{ source === 'ranking' ? 'Ranking keywords' : 'Tracking keywords'}}</span>
            </template>
            <template v-slot:content>
              <div class="select-options">
                <div class="option" @click="changeSource('ranking')">
                  <div class="circle" :class="{active: source === 'ranking'}"></div><div>Ranking keywords</div>
                </div>
                <div class="option"
                     v-if="isTrackingSourceEnabled"
                     @click="changeSource('tracking')">
                  <div class="circle" :class="{active: source === 'tracking'}"></div><div>Tracking keywords</div>
                </div>
                <custom-tooltip v-else
                                width="200px"
                                direction-vertical="bottom">
                  <template v-slot:slotTrigger>
                    <div class="option disabled">
                      <div class="circle"></div><div>Tracking keywords</div>
                    </div>
                  </template>
                  <template v-slot:slotContent v-if="!isTrackingSourceEnabled">
                    Tracked keywords are available only for your apps
                  </template>
                </custom-tooltip>
              </div>
            </template>
          </custom-dropdown>
        </div>

        <div class="content">
          <template v-if="false">
            <div class="google-play-layout">
              <div class="text-block">
                <div class="gp-title">
                  The Rank Comparative Report tool is currently only available for AppStore applications
                </div>
                <div class="gp-subtitle">
                  We are actively working on making this feature available for Google Play
                  applications to provide you
                  with a seamless experience across both platforms.
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="dataIsLoading">
            <progress-indicator>Data is loading...</progress-indicator>
          </template>
          <server-error v-else-if="showTimeoutError"
                        button-text="Contact us for help"
                        @clicked="openIntercom">
            <template v-slot:title>
              Data Overflow…
            </template>
            <template v-slot:text>
              <p>
                Due to the large volume of data received for this application, generating the report caused an error.
              </p>
              <p v-if="selectedCountries.length > 1 || selectedCountries.length === 0">
                <b>
                  Please reduce the number of countries
                </b>
                to generate a report for this application.
              </p>
              <p>
                <i>We are working to prevent this from happening in the future.</i>
              </p>
            </template>
          </server-error>
          <template v-else>
            <div v-if="noDataTitle" class="no-data-block">
            <span>
              <span class="logo">
                <app-image
                  :width="28"
                  :height="28"
                  :src="selectedApp.info?.logo"
                />
              </span>
              <span class="app-title">{{ selectedApp.info?.title }}</span>
              {{ noDataTitle }}
            </span>
            </div>
            <template v-else>
              <rank-comparative-table :data-provider="dataProvider"
                                      :store-key="this.selectedApp.store?.key"
                                      :first-date="firstDate"
                                      :last-date="lastDate"
                                      :max-impression="maxImpression"
                                      @refresh-data="refreshData"/>
            </template>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import SearchAppInput from "@/components/UI/SearchAppInput/index.vue";
import {mapGetters} from "vuex";
import DatepickerWithRange from "@/components/UI/DatepickerWithRange/index.vue";
import myApps from "@/api/modules/my-apps";
import {httpRequest} from "@/api";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown";
import CountrySelector from "@/views/Reports/components/CountrySelector/index.vue";
import {formatDate, reverseDayMonthDate} from "@/utils/utils";
import Preloader from "@/components/UI/Preloader/index.vue";
import DefaultSpinner from "@/components/UI/DefaultSpinner/index.vue";
import {GenerateQueryUrl} from "@/utils/factories";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";
import {getDefaultMinDate} from "@/utils/utils";
import FiltersNew from "@/components/UI/FiltersComponent/FiltersNew.vue";
import {SearchFilterData, PaginationData, SortingData} from "@/api/modules/tables/search-filter-data";
import {ApiDataProvider} from "@/api/modules/tables/api-data-provider";
import axios from "axios";
import RankComparativeTable from "@/views/Reports/RankComparative/components/RankComparativeTable/index.vue";
import DatepickerSingle from "@/components/UI/Dates/DatepickerSingle/index.vue";
import ServerError from "@/components/ServerError/index.vue";
import LiveSearchWithPresets from "@/components/Dropdowns/LiveSearchWithPresets/index.vue";

export default {
  name: 'RankComparative',
  components: {
    LiveSearchWithPresets,
    ServerError,
    DatepickerSingle,
    RankComparativeTable,
    FiltersNew,
    AppImage,
    DefaultSpinner,
    Preloader,
    CountrySelector,
    CustomDropdown,
    SvgIcon,
    ProgressIndicator,
    DatepickerWithRange,
    SearchAppInput,
  },
  data() {
    return {
      firstDate: new Date(),
      lastDate: new Date(),
      minDate: new Date(),
      maxDate: new Date(),
      selectedApp: {},
      dataIsLoading: true,
      source: 'ranking',
      countries: [],
      selectedCountries: null,
      myApps: [],
      liveSearchApps: [],
      appSearchLoaded: false,
      appliedFilters: {},
      dataProvider: new ApiDataProvider(new SearchFilterData(
        new PaginationData(),
        new SortingData('total_changes', 'desc', 'total_changes'),
      )),
      closeConfigDropdown: 0,
      cancelToken: axios.CancelToken.source(),
      countriesCancelToken: axios.CancelToken.source(),
      unfilteredTotal: 0,
      trekkingKeywordsTotal: 0,
      isTableDataSimplified: false,
      showTimeoutError: false,
      selectedRelease: null,
      selectedReleaseDate: null,
      releases: [],
      isReleasesLoaded: false,
      maxImpression: 0,
    };
  },
  created() {
    this.minDate = getDefaultMinDate();
    this.maxDate.setDate(this.maxDate.getDate() - 1);
    let firstDate = new Date();
    firstDate.setDate(this.lastDate.getDate() - 31);
    this.firstDate = firstDate;
    this.lastDate.setDate(this.lastDate.getDate() - 1);

    this.setParamsFromUrl();
  },
  async mounted() {
    if (this.selectedCountries === null) {
      this.selectedCountries = [];
      if (this.currentCountry) {
        this.selectedCountries = [this.currentCountry.code];
      }
    }
    if (this.currentApp?.id && this.selectedApp.id === undefined) {
      this.selectedApp = {...this.currentApp};
    }

    await this.loadReleases();
    const urlParams = this.getUrlParams();
    const release = urlParams !== null ? this.findReleaseByVersion(urlParams.get('release')) : undefined;
    if (release !== undefined) {
      this.selectedRelease = release.version;
      this.selectedReleaseDate = release.date;
    } else {
      this.setFirstAvailableRelease();
    }

    await this.fetchCountries();
    await this.fetchMyApps();
  },
  methods: {
    getUrlParams() {
      const queryString = window.location.search;
      return queryString !== '' ? new URLSearchParams(queryString) : null;
    },
    setParamsFromUrl() {
      const urlParams = this.getUrlParams();
      if (urlParams === null) {
        return;
      }

      this.firstDate = new Date(reverseDayMonthDate(urlParams.get('date_from')));
      this.lastDate = new Date(reverseDayMonthDate(urlParams.get('date_to')));
      this.source = urlParams.get('source');
      this.selectedRelease = urlParams.get('release');
      this.selectedCountries = urlParams.getAll('country_codes[]');

      const urlParamsObject = Object.fromEntries(urlParams.entries());
      Object.keys(urlParamsObject).forEach((param) => {
        if (param.includes('filter')) {
          this.appliedFilters[param] = urlParamsObject[param];
        }
      });

      this.selectedApp = {
        id: urlParamsObject.app_id
      };
    },
    formatDate(val) {
      return formatDate(val, 'month-day-year');
    },
    isReleaseDisabled(date) {
      let dateObject = new Date(date);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      return dateObject < this.minDate || dateObject > yesterday;
    },
    getFirstAvailableRelease() {
      return this.releases.find((release) => {
        return !this.isReleaseDisabled(release.date);
      });
    },
    findReleaseByVersion(version) {
      return this.releases.find((release) => {
        return release.version === version;
      });
    },
    async loadReleases() {
      this.selectedRelease = null;
      this.selectedReleaseDate = null;
      this.isReleasesLoaded = false;
      this.releases = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.VERSION_HISTORY_BLOCK
        + '?app_id=' + this.selectedApp.id
      );
      this.isReleasesLoaded = true;
    },
    setFirstAvailableRelease() {
      let firstAvailableRelease = this.getFirstAvailableRelease();
      if (firstAvailableRelease !== undefined) {
        this.changeRelease(firstAvailableRelease.version, firstAvailableRelease.date);
      } else {
        this.selectedRelease = null;
        this.selectedReleaseDate = null;
      }
    },
    openIntercom() {
      Intercom('showNewMessage');
    },
    changeCountries(codes) {
      if (codes.length !== 1) {
        this.mode = 'countries';
      }
      this.selectedCountries = codes;
    },
    changeRelease(release, date) {
      if (this.isReleaseDisabled(date)) {
        return;
      }

      this.selectedReleaseDate = date;
      this.selectedRelease = release;
      let firstDate = new Date(date);
      firstDate.setDate(firstDate.getDate() - 1);
      let lastDate = new Date(date);
      lastDate.setDate(lastDate.getDate() + 1);
      this.firstDate = firstDate;
      this.lastDate = lastDate > this.maxDate ? this.maxDate : lastDate;
    },
    changeSource(source) {
      if (source === 'tracking') {
        if (!this.isTrackingSourceEnabled) {
          return;
        }
      }

      this.source = source;
    },
    async fetchMyApps() {
      this.myApps = await myApps.getAllMyApps();
    },
    async fetchCountries() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES + this.countriesRequestUrl;
      let countries = await httpRequest('GET', url);
      if (this.countries.length === 0 && countries instanceof Array && countries.length > 0) {
        this.countries = countries;
      }
    },
    async fetchCountriesCounts() {
      if (this.requestUrl && this.source === 'tracking') {
        let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES
          + this.countriesRequestUrl + '&with_counts=1';
        this.countriesCancelToken.cancel();
        this.countriesCancelToken = axios.CancelToken.source();
        this.clearCountryCounts();
        let countries = await httpRequest('GET', url, null, {cancelToken: this.countriesCancelToken.token});
        if (countries instanceof Array && countries.length > 0) {
          this.countries = countries;
        }
      } else {
        this.clearCountryCounts();
      }
    },
    clearCountryCounts() {
      this.countries.forEach((group) => {
        group.countries.forEach((country) => {
          country.count = null;
        });
      });
    },
    async fetchTableData(showLoader) {
      if (this.requestUrl !== null) {
        this.dataIsLoading = showLoader !== false;
        this.closeConfigDropdown++;

        let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.RANK_COMPARATIVE_REPORT + this.requestUrl
          + this.dataProvider.generateRequest();
        this.cancelToken.cancel();
        this.cancelToken = axios.CancelToken.source();
        let data = await httpRequest('GET', url, null, {cancelToken: this.cancelToken.token});

        if (data.list !== undefined) {
          let tableData = data.list;
          tableData.forEach((item) => {
            item.checked = true;
          });
          this.dataProvider.setItems(tableData, data.list_total, data.totals);
          this.dataIsLoading = false;
          this.showTimeoutError = false;
          this.unfilteredTotal = data.unfiltered_total;
          this.trekkingKeywordsTotal = data.trekking_keywords_total;
          this.isTableDataSimplified = data.is_simplified;
          this.maxImpression = data.max_impression;
        } else if (data.status === 500 || data.status === 502) {
          this.showTimeoutError = true;
          this.dataIsLoading = false;
        }
      }
    },
    async searchResultsAppClicked(item) {
      this.selectedApp = {...item};
    },
    filtersApplied(e) {
      if(JSON.stringify(this.appliedFilters) !== JSON.stringify(e)) {
        this.appliedFilters = {...e};
      }
    },
    isMyApp() {
      return this.myApps.some((item) => {
        return +item.id === +this.selectedApp.id;
      });
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
    refreshData(searchFilterData) {
      this.fetchTableData(false);
    },
    collapseClickHandler() {
      let items = this.dataProvider.getItems();
      let opened = !this.isAllUncollapsed;
      items.forEach((item) => {
        item.opened = opened;
      })
      this.dataProvider.setItems(items);
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'userSubscription',
    ]),
    allowedSize() {
      return this.windowWidth >= 768;
    },
    countryForLiveSearch() {
      let countryCode = null;
      if (this.selectedCountries.length === 1) {
        countryCode = this.selectedCountries[0];
      } else if (this.selectedCountries.length > 1 && this.selectedCountries.includes(this.currentCountry?.code)) {
        countryCode = this.currentCountry.code;
      }

      let fullCountry = this.countries.find((country) => country.code === countryCode);

      return {
        code: countryCode,
        name: fullCountry?.name,
        id: fullCountry?.id,
      };
    },
    isFreeUser() {
      return this.userSubscription?.plan_name === 'Free';
    },
    downloadUrl() {
      return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_RANK_COMPARATIVE_REPORT
        + this.requestUrl + this.dataProvider.generateCSVRequest();
    },
    requestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      let url = '?app_id=' + this.selectedApp.id
        + '&date_from=' + formatDate(this.firstDate, 'digits-dash')
        + '&date_to=' + formatDate(this.lastDate, 'digits-dash')
        + '&source=' + this.source
        + GenerateQueryUrl(this.appliedFilters)
        + '&release=' + this.selectedRelease
      ;

      this.selectedCountries.forEach((code) => {
        url += '&country_codes[]=' + code;
      });

      return url;
    },
    countriesRequestUrl() {
      if (this.selectedApp.id === undefined) {
        return null;
      }

      return '?app_id=' + this.selectedApp.id
        + '&date_from=' + formatDate(this.firstDate, 'digits-dash')
        + '&date_to=' + formatDate(this.lastDate, 'digits-dash')
        + '&source=' + this.source;
    },
    filterConfigs() {
      return [
        {
          type: 'dropdown-multi',
          title: 'Changes',
          filter: 'report_changes',
          selectedValue: [
            this.appliedFilters['filter[report_changes][list][0]'] ?? null,
            this.appliedFilters['filter[report_changes][list][1]'] ?? null,
            this.appliedFilters['filter[report_changes][list][2]'] ?? null,
          ].filter(x => x),
          isInitiallyActive: false,
          defaultValue: [],
          presetsData: [
            {
              presetValue: 'Metadata changed',
              filterValue: 'metadata_changed',
            },
            {
              presetValue: 'Rank changed',
              filterValue: 'report_rank_changed',
            },
            {
              presetValue: 'Impression changed',
              filterValue: 'report_impression_changed',
            },
          ],
        },
        {
          type: 'range',
          title: 'Popularity',
          filter: 'range_sap',
          minLimit: 0,
          maxLimit: 100,
          selectedValue: {
            min: this.appliedFilters['filter[range_sap][from]'] ?? null,
            max: this.appliedFilters['filter[range_sap][to]'] ?? null,
          },
        },
      ];
    },
    isTrackingSourceEnabled() {
      return this.selectedApp.id && this.isMyApp();
    },
    noDataTitle() {
      if (this.unfilteredTotal > 0) {
        return null;
      }

      if (this.source === 'ranking') {
        return 'doesn’t have ranking keywords with estimated impression for selected countries.'
      } else {
        if (this.trekkingKeywordsTotal === 0) {
          return 'doesn’t have tracked keywords for selected countries.'
        } else {
          return 'doesn’t have tracked keywords with estimated impression for selected countries.'
        }
      }
    },
    firstDatePresets() {
      let day = this.selectedReleaseDate ? new Date(this.selectedReleaseDate) : new Date();
      day.setDate(day.getDate() - 1);
      let week = this.selectedReleaseDate ? new Date(this.selectedReleaseDate) : new Date();
      week.setDate(week.getDate() - 7);
      let month = this.selectedReleaseDate ? new Date(this.selectedReleaseDate) : new Date();
      month.setMonth(month.getMonth() - 1);
      return [
        {
          title: '1 Day Before',
          date: day,
        },
        {
          title: '1 Week Before',
          date: week,
        },
        {
          title: '1 Month Before',
          date: month,
        },
      ];
    },
    lastDatePresets() {
      if (this.selectedReleaseDate === null) {
        return [];
      }

      let day = new Date(this.selectedReleaseDate);
      day.setDate(day.getDate() + 1);
      let week = new Date(this.selectedReleaseDate);
      week.setDate(week.getDate() + 7);
      let month = new Date(this.selectedReleaseDate);
      month.setMonth(month.getMonth() + 1);
      return [
        {
          title: '1 Day After',
          date: day,
        },
        {
          title: '1 Week After',
          date: week,
        },
        {
          title: '1 Month After',
          date: month,
        },
      ];
    },
    isAllUncollapsed() {
      return this.dataProvider.getItems().every((item) => item.opened === true);
    },
  },
  watch: {
    currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.selectedApp = this.currentApp;
      }
    },
    async selectedApp(newVal, oldVal) {
      if (newVal.id) {
        if (!this.isMyApp() && this.source === 'tracking') {
          this.source = 'ranking';
        }
        if (newVal.id !== oldVal.id && oldVal.id !== undefined) {
          await this.loadReleases();
          this.setFirstAvailableRelease();
        }
        if (this.selectedApp.info === undefined) {
          let appData = await myApps.getApp(this.selectedApp.id, this.currentCountry.code);
          this.selectedApp.info = appData.app_info;
          this.selectedApp.store = {
            key: appData.app_info.store
          };
        }
      }
    },
    requestUrl() {
      if (this.lastDate < this.firstDate) {
        [this.lastDate, this.firstDate] = [this.firstDate, this.lastDate];
      } else {
        this.dataProvider.resetPagination();
        if (window.history.replaceState) {
          window.history.replaceState({url: this.requestUrl}, '', this.requestUrl);
        }
        this.fetchTableData();
      }
    },
    countriesRequestUrl() {
      this.fetchCountriesCounts();
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
