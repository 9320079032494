<template>
  <div v-if="isNaN(change)" class="value-change" :class="{down: change === 'out'}">
    {{ change }}
  </div>
  <div v-else class="value-change" :class="{down: change < 0}">
    <template v-if="change > 0 ">
      <img src="@/assets/svg/arrow-up-alt.svg"
           width="12"
           height="12"
           alt="Arrow up"/>
      {{ shortenDigits(change) }}
    </template>
    <template v-else-if="change < 0">
      <img src="@/assets/svg/arrow-down-alt.svg"
           width="12"
           height="12"
           alt="Arrow down"/>
      {{ shortenDigits(absoluteValue(change)) }}
    </template>
  </div>
</template>

<script>
import {shortenDigits} from "@/utils/utils";
export default {
  name: 'ValueChange',
  props: {
    change: [Number, String],
  },
  methods: {
    shortenDigits,
    absoluteValue(value) {
      return Math.abs(value);
    },
  },
}
</script>

<style>
.value-change {
  color: #6bd790;

  &.down {
    color: #f17a54;
  }
}
</style>
