<template>
  <div class="error-block">
    <img src="@/assets/images/tables/server_error.svg">
    <div class="error-content">
      <div class="error-title">
        <slot name="title"></slot>
      </div>
      <div class="error-description">
        <slot name="text"></slot>
      </div>
      <base-button v-if="buttonText"
                   height="45px"
                   width="185px"
                   font-size="16px"
                   border-radius="4px"
                   :mode="'btn-standard'"
                   :id="buttonId"
                   @click="buttonClickHandler">
        {{ buttonText }}
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServerError',
  emits: ['clicked'],
  props: {
    buttonText: String,
    buttonId: String,
  },
  methods: {
    buttonClickHandler() {
      this.$emit('clicked');
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>
